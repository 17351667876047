import React from 'react'
import { Box, Typography } from '@mui/material'

const ReviewListsIntroductions = ({ reviewType, cohortCount }) => {
  let title = ''
  let description = ''

  switch (reviewType) {
    case 'submission':
      title = 'Classes in Submission Review'
      description =
        'The submission date for these classes has passed. Please check that enough students submitted, then send each class review to the curators.'
      break
    case 'curatorial':
      title = 'Classes in Curatorial Review'
      description = (
        <>
          These classes are currently in curatorial review. Classes remain on this list until <i>all</i> curators for the class submit their review.
        </>
      )
      break
    case 'award':
      title = 'Classes in Award Summary Review'
      description =
        'All of the curators for each class on this list have submitted their curatorial review. Please identify models in awarded photographs, then send review summaries for each class.'
      break
    case 'publishing':
      title = 'Classes in Form Review'
      description =
        'Students, teachers, and teaching artists for these classes have been notified of awards. Please review Use Agreement and Model Release forms as applicable.'
      break
    case 'completed':
      title = 'Classes with Completed Reviews'
      description = (
        <>
          All of the curators for each class on this list have submitted their curatorial review. The <i>wrkxfmly</i> administrator will now process awards and review permissions forms for awarded photographs from these classes.
        </>
      )
      break
    default:
      break
  }

  return (
    <Box sx={{mt: {xs: 1, sm: 0}}}>
      <Box>
        <Typography variant="h1" className="bold">
          {title} ({cohortCount})
        </Typography>
      </Box>
      <Box sx={{ mt: 2, maxWidth: {md: '510px', lg:'700px'}, mb: { xs: 3, sm:3, md: 0 } }}>
        <Typography variant="paragraph" component="p">
          {description}
        </Typography>
      </Box>
    </Box>
  )
}

export default ReviewListsIntroductions
