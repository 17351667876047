import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Box, Grid, Typography } from '@mui/material'
import { inject } from 'mobx-react'
import moment from 'moment'
import { Spinner } from 'react-bootstrap'
import { Send } from 'react-feather'
import { Link } from 'react-router-dom'
import { getFormattedStatus } from '../../utils/commonFunctions.js'
import Button from '../elements/Button.js'
import DatePicker from '../elements/DatePicker.js'
import ReopenCuratorialReviewModal from '../molecules/ReopenCuratorialReviewModal.js'
import SendSummaryModal from '../molecules/SendSummaryModal.js'
import SubmitCuratorialReviewAdminModal from '../molecules/SubmitCuratorialReviewAdminModal.js'
import SubmitCuratorialReviewModal from '../molecules/SubmitCuratorialReviewModal.js'
import UserDetailsModal from '../molecules/UserDetailsModal.js'
import './awardProcessing.scss'
import DateUtils from '../../utils/DateUtils.js'
import {
  SEND_CURATORS_REVIEW_MUTATION
} from '../../queries/reviewQueries.js'
import { REVIREW_BY_COHORT_ID } from '../../queries/ClassReviewTabQueries.js'
import Loading from '../elements/Loading.js'
import ErrorPage from '../../pages/ErrorPage.jsx'

const AwardProcessing = props => {
  const { userStore } = props
  const { role, profileId } = userStore

  const [isEducatorSummarySend, setIsEducatorSummarySend] = useState(false)
  const [modalCuratorialReviewShow, setModalCuratorialReviewShow] = useState(false)
  const [summaryType, setSummaryType] = useState('')
  const [curatorDueDate, setCuratorDueDate] = useState()
  const [errors, setErrors] = useState({})
  const [showReopenCuratorReviewModal, setShowReopenCuratorReviewModal] = useState(false)
  const [curatorReviewSendLoading, setCuratorReviewSendLoading] = useState(false)
  const [cohort, setCohort] = useState()
  const [modalSummaryShow, setModalSummaryShow] = useState(false)
  const [modalCuratorDetailsShow, setModalCuratorDetailsShow] = useState(false)
  const [modalUserIndex, setModalUserIndex] = useState()
  const [refetchData, setRefetchData] = useState(false)

  const containsProperty = (cohort, property) => {
    return (
      cohort.submittedProjects
        .map(proj => proj.photos.filter(photo => photo[property]))
        .filter(photosArr => photosArr.length > 0).length > 0
    )
  }

  const { loading, error, refetch } = useQuery(REVIREW_BY_COHORT_ID, {
    skip: !props?.cohort?.id,
    variables: {
      id: props?.cohort?.id
    },
    onCompleted(data) {
      setCohort(data.cohort)
      setIsEducatorSummarySend(containsProperty(data.cohort, 'summarySent'))
    }
  })

  const [sendReviewToCuratorsMutation] = useMutation(SEND_CURATORS_REVIEW_MUTATION, {
    onCompleted: () => {
      refetch()
      let message = cohort.isReviewSend
        ? 'New Due Date has been sent to Curators'
        : 'Review has been sent to Curators.'
      props.showToast('success', message)
      setCuratorReviewSendLoading(false)
    }
  })

  useEffect(() => {
    if (refetchData) {
      refetch()
      setRefetchData(false)
    }
  }, [refetchData, refetch])

  if (loading)
    return (
      <Typography component={'p'} textAlign={'center'}>
        <Loading />
      </Typography>
    )
  if (error) return <ErrorPage errorCode='403' withInPage={true} />

  const handleSubmitSummary = (e, summaryType) => {
    e.preventDefault()
    setModalSummaryShow(true)
    setSummaryType(summaryType)
    // sendSummaryMutation({
    //   variables: {
    //     cohortId: cohort.id,
    //     dueDate:
    //     DateUtils.createMoment(cohort.curatorsDueDate).toDate()
    //   }
    // })
  }

  const handleFormChange = e => {
    if (e.target.name === 'curatorDueDate') {
      setCuratorDueDate(DateUtils.createMoment(e.target.value).toDate())
    }
    validateChange(e)
  }

  const validateChange = e => {
    const fieldName = e.target.name
    const value = DateUtils.createMoment(e.target.value).format('YYYY-MM-DD')
    switch (fieldName) {
      case 'curatorDueDate':
        const curatorDueDate = value
        const earliestDate = DateUtils.createMoment().format('YYYY-MM-DD')
        if (moment(curatorDueDate).isBefore(earliestDate)) {
          setErrors({
            curatorDueDate: 'Please enter a current or future date.'
          })
        } else {
          setErrors({ ...errors, curatorDueDate: null })
        }
        break
      default:
        return
    }
  }

  const sendReviewToCurators = e => {
    setCuratorReviewSendLoading(true)
    e.preventDefault()
    sendReviewToCuratorsMutation({
      variables: {
        cohortId: cohort.id,
        dueDate: DateUtils.createMoment(curatorDueDate).format('YYYY-MM-DD')
      }
    })
  }

  const getCuratorReviewStatus = () => {
    return (
      <Box sx={{ mt: { xs: 2 } }}>
        {cohort.curators.map(curator => {
          let review = cohort.curatorReviews.filter(rev => rev.reviewerId === curator.id)
          if (review.length > 0) {
            return (
              <Box key={curator.id}>
                {getFormattedStatus(
                  'CheckCircle',
                  `${curator.name} Submitted Review on ${moment(
                    review[0].createdAt,
                    'YYYY-MM-DD'
                  ).format('MMMM D, YYYY')}`
                )}
              </Box>
            )
          } else {
            return (
              <Box className='student-list__alert-warning' key={curator.id}>
                {getFormattedStatus('Clock', `${curator.name} Has Not Submitted Review`)}
              </Box>
            )
          }
        })}
      </Box>
    )
  }

  const getReviewStatusForCurators = () => {
    const currentUserReview = cohort.curatorReviews.filter(
      review => review.reviewerId === profileId
    )
    const otherCuratorReviews = cohort.curatorReviews.filter(
      review => review.reviewerId !== profileId
    )
    const otherCurators = cohort.curators.filter(curator => curator.id !== profileId)
    return (
      <>
        <Box sx={{ mt: { xs: 3 } }}>
          <Typography variant='h4' className='strong'>
            Your review status:
          </Typography>
          <Box>
            {currentUserReview.length > 0 ? (
              <Box className='student-list__alert--success review-submission-status-text'>
                {getFormattedStatus(
                  'CheckCircle',
                  `You Submitted Your Review on ${moment(
                    currentUserReview[0].createdAt,
                    'YYYY-MM-DD'
                  ).format('MMMM D, YYYY')}`
                )}
              </Box>
            ) : (
              <Box sx={{ mt: { xs: 2 } }}>
                {getFormattedStatus('Clock', 'Select Awards and Submit Your Review')}
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={{ mt: { xs: 3 } }}>
          <Typography variant='h4' className='strong'>
            Other curators on this review:
          </Typography>
          <Box>
            {otherCurators.map(curator => {
              let curatorReview = otherCuratorReviews.filter(rev => rev.reviewerId === curator.id)
              if (curatorReview.length > 0) {
                return (
                  <Box
                    className='student-list__alert--success review-submission-status-text'
                    key={curator.id}
                  >
                    {getFormattedStatus(
                      'CheckCircle',
                      `${curator.name} Submitted Review on ${moment(
                        curatorReview[0].createdAt,
                        'YYYY-MM-DD'
                      ).format('MMMM D, YYYY')}`
                    )}
                  </Box>
                )
              } else {
                return (
                  <Box
                    className='student-list__alert-warning review-submission-status-text'
                    key={curator.id}
                  >
                    {getFormattedStatus('Clock', `${curator.name} Has Not Submitted Review`)}
                  </Box>
                )
              }
            })}
          </Box>
        </Box>
      </>
    )
  }

  const getCuratorCompletedDate = () => {
    let curatorsDates = cohort.curatorReviews.map(review =>
      DateUtils.createMoment(review.createdAt)
    )
    return moment.max(curatorsDates)
  }

  const modalClose = () => {
    setModalSummaryShow(false)
    setModalCuratorDetailsShow(false)
  }

  if (cohort) {
    const cohortPhotos = cohort.photos
    const publishablePhotos = cohortPhotos.filter(photo => photo.readyToPublish)

    const awardedPhotos = cohortPhotos.filter(
      photo => photo.awards.length > 0 && photo.isShortListed
    )
    const isCohortReviewed =
      cohort.curators.length > 0 && cohort.curators.length === cohort.curatorReviews.length
    const isCohortEnded = moment(cohort.endDate, 'YYYY-MM-DD').isBefore(
      moment().format('YYYY-MM-DD')
    )
    const isCohortSendForReview = cohort.isReviewSend

    const currentUserReview = cohort.curatorReviews.filter(
      review => review.reviewerId === profileId
    )
    const completedAwardedPhotos = awardedPhotos.filter(
      photo => photo.hasPeople === false || (photo.hasPeople === true && photo.totalMrRequired > 0)
    ).length

    const shouldReviewButtonDisable =
      awardedPhotos.length > 0 && completedAwardedPhotos !== awardedPhotos.length

    return (
      <Box sx={{ px: { xs: 3, sm: 4, md: 6 }, pt: { xs: 5, md: 6, lg: 10 }, pb: { xs: 6 } }}>
        <Box sx={{ pb: { xs: 7, sm: 10 } }}>
          <Typography className='bold' variant='h1'>
            {!isCohortEnded
              ? 'Class Has Not Submitted'
              : isCohortEnded && !isCohortSendForReview
              ? 'Class Is in Submission Review'
              : isCohortEnded && isCohortSendForReview && !isCohortReviewed
              ? 'Class Is in Curatorial Review'
              : isCohortReviewed && !isEducatorSummarySend
              ? role === 'appadmin'
                ? 'Class Is in Award Summary Review'
                : 'Class Review is Complete'
              : isCohortReviewed && isEducatorSummarySend
              ? 'Class Is in Form Review'
              : ''}
          </Typography>
          <Box sx={{ mt: { xs: 2 } }}>
            {!isCohortEnded ? (
              <Typography variant='paragraph' component='p'>
                This class is still active, and students have not completed their assignment. After
                the assignment submission due date, you can check submissions and send the review to
                curators.
              </Typography>
            ) : isCohortEnded && !isCohortSendForReview && role === 'appadmin' ? (
              <Grid container>
                <Grid item md={8} sm={12} xs={12}>
                  <Typography variant='paragraph' component='p'>
                    The assignment submission date for this class has passed.
                  </Typography>
                  <Box sx={{ mt: { xs: 7, sm: 6 } }}>
                    <Typography variant='h2' className='strong'>
                      Check Project Submissions and Send Review to Curators
                    </Typography>
                    <Typography variant='paragraph' component='ol' sx={{ pl: 2, mt: 2 }}>
                      <li>
                        Check that enough students have submitted projects for this class to be
                        ready for curatorial review. If submission rate was low, consider talking to
                        the class teacher about extending the submission date.
                      </li>
                      <li>Assign a review due date and send the review to curators.</li>
                      <li>
                        Once the review is sent:
                        <ul>
                          <li>
                            curators for this class will receive an email letting them know that the
                            class is available to review.
                          </li>
                          <li>
                            curators for this class will see the review in the app next time they
                            sign in.
                          </li>
                        </ul>
                      </li>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            ) : isCohortEnded && isCohortSendForReview && !isCohortReviewed ? (
              <Grid container>
                <Grid item md={7} xs={12}>
                  {role === 'appadmin' ? (
                    <Box>
                      <Typography variant='paragraph' component='p'>
                        After all the curators submit their review for this class, you will be asked
                        to process awards and notify students.
                      </Typography>
                      {getCuratorReviewStatus()}
                    </Box>
                  ) : (
                    <Box>
                      {getReviewStatusForCurators()}
                      <Box sx={{ mt: '32px' }}>
                        {currentUserReview.length > 0 ? (
                          <Typography variant='paragraph' component='p'>
                            Thank you for submitting your curatorial review. You can no longer edit
                            awards for this class. After <i>all</i> curators for the class submit
                            their review, the review will be considered complete and the{' '}
                            <i>wrkxfmly</i> administrator will process the awarded photographs.
                          </Typography>
                        ) : (
                          <Typography variant='paragraph' component='p'>
                            When you are happy with your choice of awards for this class, please
                            submit your review. Other curators for this class will be notified by
                            email.
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  )}
                </Grid>
              </Grid>
            ) : isCohortReviewed && !isEducatorSummarySend && role === 'appadmin' ? (
              <Box className='award-processing__review-summary-section--not-sent'>
                <Typography variant='paragraph' component='p'>
                  All of the curators for this class have submitted their curatorial review.
                </Typography>
                {getCuratorReviewStatus()}
                <Typography variant='h2' className='strong' sx={{ mt: { xs: 7, sm: 6 } }}>
                  Process Awarded Photographs and Send Summary
                </Typography>
                <Box component={'div'}>
                  <Typography variant='paragraph' component='ol' sx={{ pl: { xs: 2 }, mt: 2 }}>
                    <li>
                      Via the ‘Class Photographs’ tab, begin the Model Release review process
                      (adding models and notes, where applicable).
                    </li>
                    <li>Once complete, return here to send the review summary.</li>
                    <li>
                      Once the review summary is sent:
                      <ul>
                        <li>
                          students will automatically receive an email with the results of their
                          review.
                        </li>
                        <li>
                          the teacher and teaching artist will automatically receive an email with a
                          summary of all class awards.
                        </li>
                        <li>all users who next sign in to the web app will see award details.</li>
                      </ul>
                    </li>
                  </Typography>
                </Box>
                {/* <Mutation
                  mutation={SEND_SUMMARY_MUTATION}
                  onCompleted={() => {
                    props.showToast('success', 'Review summary sent.')
                  }}
                >
                  {(onMutate, { data }) => (*/}
                <>
                  <Box className='award-processing__review-summary-section--button-section'>
                    <Grid container sx={{ mt: { xs: 3 } }}>
                      <Grid item xs={12} sm={6}>
                        <Button
                          onClick={e => handleSubmitSummary(e, 'online')}
                          variant='outlined'
                          title={
                            <span className='icon-text-span'>
                              <Send size={18} /> Send Review Summary
                            </span>
                          }
                          disabled={isEducatorSummarySend || shouldReviewButtonDisable}
                          sx={{ width: { xs: '100%', md: 'auto' } }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={10} lg={7}>
                        <Typography variant='note' component='h3'>
                          Review summary cannot be sent until all awarded photographs have Model
                          Release notes.
                        </Typography>
                      </Grid>
                    </Grid>

                    <SendSummaryModal
                      show={modalSummaryShow}
                      onHide={modalClose}
                      photoIds={[...publishablePhotos].map(photo => parseInt(photo.id))}
                      cohortId={cohort.id}
                      summaryType={summaryType}
                      setRefetchData={setRefetchData}
                      showToast={props.showToast}
                    />
                  </Box>
                  <Grid container sx={{ mt: { xs: 3 } }} gap={{ xs: 3 }}>
                    <Grid item xs={12} sm={10} lg={7}>
                      <Typography variant='paragraph' component='p'>
                        If the summary for this class was already sent outside of the web app,
                        please indicate this below.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        onClick={e => handleSubmitSummary(e, 'offline')}
                        variant='outlined'
                        title='Mark Summary as Sent'
                        disabled={isEducatorSummarySend || shouldReviewButtonDisable}
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                      />
                    </Grid>
                  </Grid>
                </>
                {/* )}
                </Mutation> */}
              </Box>
            ) : isCohortReviewed && role === 'curator' ? (
              <Box>
                {getReviewStatusForCurators()}
                <Grid container sx={{ mt: { xs: 4 } }}>
                  <Grid item xs={12} sm={10} lg={7}>
                    <Typography variant='paragraph' component='p'>
                      Thank you for submitting your curatorial review. You can no longer edit awards
                      for this class. The <i>wrkxfmly</i> administrator will now process the awarded
                      photographs and notify students, teachers, and teaching artists.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            ) : isCohortReviewed && isEducatorSummarySend && role === 'appadmin' ? (
              <>
                <Box className='award-processing__submission-review--description'>
                  <Typography variant='paragraph' component='p'>
                    Students have been notified of awards and any missing forms. As signed forms become available, please review any new Use Agreements and/or Model Releases for awarded photographs.
                  </Typography>
                </Box>
                <Box>
                  {['appadmin'].includes(role) ? (
                    isCohortReviewed && !isEducatorSummarySend ? null : isEducatorSummarySend ? (
                      <Box sx={{ mt: { xs: 7, sm: 6 } }}>
                        <Typography variant='h2' className='strong'>
                          Review Summary Date
                        </Typography>
                        <Typography variant='paragraph' component='p'>
                          {cohort.educatorSummaryAt
                            ? DateUtils.createMoment(cohort.educatorSummaryAt).format(
                                'MMMM D, YYYY'
                              )
                            : DateUtils.createMoment().format('MMMM D, YYYY')}
                        </Typography>
                      </Box>
                    ) : null
                  ) : null}
                </Box>
                {getCuratorReviewStatus()}
              </>
            ) : null}

            {isCohortEnded && !isCohortReviewed && role === 'appadmin' ? (
              <>
                {isCohortSendForReview ? (
                  <Box sx={{ mt: { xs: 7, sm: 6 } }}>
                    <Typography variant='h2' className='strong'>
                      Review Due Date
                    </Typography>
                    <Grid container sx={{ mt: { xs: 2 } }}>
                      <Grid item xs={12} sm={10} lg={7}>
                        <Typography variant='paragraph' component='p'>
                          If you need to change the due date for this review, you can do so below.
                          Curators will receive an email letting them know that the review due date
                          has changed.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                ) : null}

                <Grid container sx={{ mt: { xs: 3, sm: 5 } }} gap={{ xs: 3 }}>
                  <Grid item xs={12} sm={6.2} md={4} lg={3}>
                    <DatePicker
                      onChange={date =>
                        handleFormChange({
                          target: {
                            value: date,
                            name: 'curatorDueDate'
                          }
                        })
                      }
                      value={curatorDueDate}
                      helperText={errors.curatorDueDate}
                      error={errors.curatorDueDate}
                      label='Review Due Date'
                      className='custom-input-bg'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6.2} md={12}>
                    <Button
                      onClick={sendReviewToCurators}
                      variant={isCohortSendForReview ? 'outlined' : 'contained'}
                      sx={{ width: { xs: '100%', md: 'auto' } }}
                      title={
                        curatorReviewSendLoading ? (
                          <Typography variant='span' className='icon-text-span'>
                            <Spinner
                              as='span'
                              animation='border'
                              size='sm'
                              role='status'
                              aria-hidden='true'
                            />{' '}
                            Sending...
                          </Typography>
                        ) : (
                          <Box className='icon-text-span'>
                            <Send size={16} />
                            {isCohortSendForReview
                              ? 'Send New Due Date to Curators'
                              : 'Send Review to Curators'}
                          </Box>
                        )
                      }
                      disabled={
                        !curatorDueDate || errors.curatorDueDate || curatorReviewSendLoading
                      }
                    />
                  </Grid>
                </Grid>
              </>
            ) : null}

            {isCohortEnded &&
            isCohortSendForReview &&
            !isEducatorSummarySend &&
            role === 'appadmin' ? (
              <>
                <Typography variant='h2' className='strong' sx={{ mt: { xs: 7, sm: 6 } }}>
                  Reopen Curatorial Review(s)
                </Typography>
                <Grid container sx={{ mt: { xs: 2 } }} gap={{ xs: 3 }}>
                  <Grid item xs={12} sm={10} lg={7}>
                    <Typography variant='paragraph' component='p'>
                      After curators submit, they can no longer change their award designations. You
                      can reopen this review for one or more curators below.
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Button
                      onClick={e => {
                        e.preventDefault()
                        setShowReopenCuratorReviewModal(true)
                      }}
                      sx={{ width: { xs: '100%', md: 'auto' } }}
                      color='primary'
                      title='Reopen Curatorial Review(s)'
                      disabled={cohort.curatorReviews.length < 1}
                    />
                  </Grid>
                </Grid>
                <ReopenCuratorialReviewModal
                  show={showReopenCuratorReviewModal}
                  showToast={props.showToast}
                  onHide={() => setShowReopenCuratorReviewModal(false)}
                  curators={cohort.curators}
                  curatorReviews={cohort.curatorReviews}
                />
              </>
            ) : null}

            {currentUserReview.length > 0 && role === 'curator' ? null : (
              <Box>
                {role === 'appadmin' && !isCohortReviewed && isCohortSendForReview ? (
                  <>
                    <Box sx={{ mt: { xs: 7, sm: 6 } }}>
                      <Typography variant='h2' className='strong'>
                        Submit Review(s)
                      </Typography>
                      <Grid container sx={{ mt: { xs: 2 } }} gap={{ xs: 3 }}>
                        <Grid item xs={12} sm={10} lg={7}>
                          <Typography variant='paragraph' component='p'>
                            If one or more curators is unable to submit their own review, you can
                            submit on their behalf using the button below.
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Button
                            onClick={e => {
                              e.preventDefault()
                              setModalCuratorialReviewShow(true)
                            }}
                            sx={{ width: { xs: '100%', md: 'auto' } }}
                            color='primary'
                            title={'Submit Curatorial Review(s)'}
                          />
                        </Grid>
                      </Grid>
                      <SubmitCuratorialReviewAdminModal
                        showToast={props.showToast}
                        cohortId={props.cohort.id}
                        curators={cohort.curators}
                        curatorReviews={cohort.curatorReviews}
                        show={modalCuratorialReviewShow}
                        onHide={() => {
                          setModalCuratorialReviewShow(false)
                        }}
                      />
                    </Box>
                  </>
                ) : role === 'curator' ? (
                  <>
                    <Grid container sx={{ mt: { xs: 3 } }}>
                      <Grid item xs={12} sm={6}>
                        <Button
                          onClick={e => {
                            e.preventDefault()
                            setModalCuratorialReviewShow(true)
                          }}
                          variant='contained'
                          title={'Submit Your Curatorial Review'}
                          sx={{ width: { xs: '100%', md: 'auto' } }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={10} lg={7}>
                        <Typography variant='note' component='p'>
                          You will not be able to edit your awards after you submit this review.
                        </Typography>
                      </Grid>
                    </Grid>
                    <SubmitCuratorialReviewModal
                      showToast={props.showToast}
                      cohortId={props.cohort.id}
                      show={modalCuratorialReviewShow}
                      onHide={() => {
                        setModalCuratorialReviewShow(false)
                      }}
                    />
                  </>
                ) : null}
              </Box>
            )}
          </Box>
        </Box>
        <Box className='award-processing__more-information-section'>
          <Typography variant='h2' className='strong' sx={{ mt: { xs: 6 } }}>
            More about This Review
          </Typography>
          <Grid container sx={{ mt: { xs: 4 } }} rowGap={{ xs: 4 }}>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant='dataLabel' component='div'>
                Review Status
              </Typography>
              <Typography variant='dataLabel' className='content' component='div'>
                {!isCohortEnded
                  ? 'Class Has Not Submitted'
                  : isCohortEnded && !isCohortSendForReview
                  ? 'In Submission Review'
                  : isCohortEnded && isCohortSendForReview && !isCohortReviewed
                  ? 'In Curatorial Review'
                  : isCohortReviewed && !isEducatorSummarySend
                  ? role === 'appadmin'
                    ? 'In Award Summary Review'
                    : 'Review Complete'
                  : isCohortReviewed && isEducatorSummarySend
                  ? 'In Form Review'
                  : ''}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant='dataLabel' component='div'>
                Assignment Submission Due Date
              </Typography>
              <Typography variant='dataLabel' className='content' component='div'>
                {moment(cohort.endDate, 'YYYY-MM-DD').format('MMMM D, YYYY')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant='dataLabel' component='div'>
                Curators
              </Typography>
              {cohort.curators.map((curator, index) => (
                <Box key={index} className='class-details__curator-names'>
                  <Link
                    to='#'
                    onClick={() => {
                      setModalCuratorDetailsShow(true)
                      setModalUserIndex(index)
                    }}
                  >
                    <Typography variant='link' className='labeled-data large'>
                      {curator.name}
                    </Typography>
                  </Link>
                  <UserDetailsModal
                    show={modalCuratorDetailsShow && modalUserIndex === index}
                    onHide={modalClose}
                    name={curator.name}
                    bio={curator.bio}
                    userType='Curator'
                    website={curator.website}
                    email={curator.email}
                    mobileNumber={curator.mobileNumber}
                    instagramHandle={curator.instagramHandle}
                    userId={curator.userId}
                  />
                </Box>
              ))}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant='dataLabel' component='div'>
                Curatorial Review Start Date
              </Typography>
              <Typography variant='dataLabel' className='content' component='div'>
                {cohort.sendForReviewOn
                  ? moment(cohort.sendForReviewOn, 'YYYY-MM-DD').format('MMMM D, YYYY')
                  : 'Review Has Not Started'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant='dataLabel' component='div'>
                Curatorial Review Due Date
              </Typography>
              <Typography variant='dataLabel' className='content' component='div'>
                {cohort.curatorsDueDate
                  ? moment(cohort.curatorsDueDate, 'YYYY-MM-DD').format('MMMM D, YYYY')
                  : 'Due Date Not Assigned'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant='dataLabel' component='div'>
                Curatorial Review Completed Date
              </Typography>
              <Typography variant='dataLabel' className='content' component='div'>
                {cohort.curatorReviews.length > 0 &&
                cohort.curatorReviews.length === cohort.curators.length
                  ? getCuratorCompletedDate().format('MMMM D, YYYY')
                  : 'Curatorial Review Not Completed'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    )
  }
}
// class AwardProcessing extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       submitProjectsBtnDisabled: props.isPublished,
//       isEducatorSummarySend: props.summaryBeenSent,
//       isStudentSummarySend: props.studentSummaryBeenSent,
//       reviewSent: props.reviewSent,
//       modalCuratorialReviewShow(false)
//       summaryType: '',
//       curatorDueDate: DateUtils.createMoment(props.curatorsDueDate).toDate(),
//       errors: {},
//       reivewDateSendingLoading: false,
//       showReopenCuratorReviewModal: false,
//       curatorReviewSendLoading: false
//     }
//   }

//   static getDerivedStateFromProps(nextProps, prevState) {
//     if (nextProps.summaryBeenSent !== prevState.isEducatorSummarySend) {
//       return { isEducatorSummarySend: nextProps.summaryBeenSent } // <- this is setState equivalent
//     }

//     if (nextProps.studentSummaryBeenSent !== prevState.isStudentSummarySend) {
//       return { isStudentSummarySend: nextProps.studentSummaryBeenSent } // <- this is setState equivalent
//     }

//     return null
//   }

//   handleRevertReview = (e, onMutate) => {
//     e.preventDefault()
//     this.setState({
//       ...this.state,
//       modalRevertShow: true
//     })
//   }

//   handleSubmitSummary(e, onMutate, summaryType) {
//     e.preventDefault()
//     this.setState({
//       modalSummaryShow: true,
//       summaryType: summaryType
//     })
//   }

//   handleSubmitPublish(e, onMutate) {
//     e.preventDefault()
//     this.setState({ modalPublishPhotosShow: true, submitProjectsBtnDisabled: true })
//   }

//   handleFormChange(e) {
//     this.setState({ [e.target.name]: DateUtils.createMoment(e.target.value).toDate() })
//     this.validateChange(e)
//   }

//   validateChange(e) {
//     const fieldName = e.target.name
//     const value = e.target.value
//     switch (fieldName) {
//       case 'curatorDueDate':
//         let curatorDueDate = moment(value)
//         let earliestDate = moment().startOf('day')

//         if (curatorDueDate.isBefore(earliestDate)) {
//           this.setState({
//             errors: {
//               ...this.state.errors,
//               curatorDueDate: 'Please enter a current or future date.'
//             }
//           })
//         } else {
//           this.setState({ errors: { ...this.state.errors, curatorDueDate: null } })
//         }
//         break
//       default:
//         return
//     }
//   }

//   sendReviewToCurators(e, onMutate) {
//     e.preventDefault()
//     onMutate({
//       variables: {
//         cohortId: this.props.cohort.id,
//         dueDate: DateUtils.createMoment(this.state.curatorDueDate).toDate()
//       },
//       refetchQueries: [`ClassReview`]
//     })
//   }

//   getCuratorReviewStatus = () => {
//     const { cohort } = this.props
//     return (
//       <Box sx={{ mt: { xs: 2 } }}>
//         {cohort.curators.map(curator => {
//           let review = cohort.curatorReviews.filter(rev => rev.reviewerId === curator.id)
//           if (review.length > 0) {
//             return (
//               <Box key={curator.id}>
//                 {getFormattedStatus(
//                   'CheckCircle',
//                   `${curator.name} Submitted Review on ${moment(
//                     review[0].createdAt,
//                     'YYYY-MM-DD'
//                   ).format('MMMM D, YYYY')}`
//                 )}
//               </Box>
//             )
//           } else {
//             return (
//               <Box className='student-list__alert-warning' key={curator.id}>
//                 {getFormattedStatus('Clock', `${curator.name} Has Not Submitted Review`)}
//               </Box>
//             )
//           }
//         })}
//       </Box>
//     )
//   }

//   getReviewStatusForCurators = cohort => {
//     const { userStore } = this.props
//     const { profileId } = userStore
//     const currentUserReview = cohort.curatorReviews.filter(
//       review => review.reviewerId === profileId
//     )
//     const otherCuratorReviews = cohort.curatorReviews.filter(
//       review => review.reviewerId !== profileId
//     )
//     const otherCurators = cohort.curators.filter(curator => curator.id !== profileId)
//     return (
//       <>
//         <Box sx={{ mt: { xs: 3 } }}>
//           <Typography variant='h4' className='strong'>
//             Your review status:
//           </Typography>
//           <Box>
//             {currentUserReview.length > 0 ? (
//               <Box className='student-list__alert--success review-submission-status-text'>
//                 {getFormattedStatus(
//                   'CheckCircle',
//                   `You Submitted Your Review on ${moment(
//                     currentUserReview[0].createdAt,
//                     'YYYY-MM-DD'
//                   ).format('MMMM D, YYYY')}`
//                 )}
//               </Box>
//             ) : (
//               <Box sx={{ mt: { xs: 2 } }}>
//                 {getFormattedStatus('Clock', 'Select Awards and Submit Your Review')}
//               </Box>
//             )}
//           </Box>
//         </Box>
//         <Box sx={{ mt: { xs: 3 } }}>
//           <Typography variant='h4' className='strong'>
//             Other curators on this review:
//           </Typography>
//           <Box>
//             {otherCurators.map(curator => {
//               let curatorReview = otherCuratorReviews.filter(rev => rev.reviewerId === curator.id)
//               if (curatorReview.length > 0) {
//                 return (
//                   <Box className='student-list__alert--success review-submission-status-text' key={curator.id}>
//                     {getFormattedStatus(
//                       'CheckCircle',
//                       `${curator.name} Submitted Review on ${moment(
//                         curatorReview[0].createdAt,
//                         'YYYY-MM-DD'
//                       ).format('MMMM D, YYYY')}`
//                     )}
//                   </Box>
//                 )
//               } else {
//                 return (
//                   <Box className='student-list__alert-warning review-submission-status-text' key={curator.id}>
//                     {getFormattedStatus('Clock', `${curator.name} Has Not Submitted Review`)}
//                   </Box>
//                 )
//               }
//             })}
//           </Box>
//         </Box>
//       </>
//     )
//   }

//   getCuratorCompletedDate = cohort => {
//     let curatorsDates = cohort.curatorReviews.map(review => moment(review.createdAt))
//     return moment.max(curatorsDates)
//   }

//   render() {
//     let modalClose = () =>
//       this.setState({
//         modalSummaryShow: false,
//         modalPublishPhotosShow: false,
//         modalUserDetailsShow: false,
//         modalCuratorDetailsShow: false
//       })

//     const { isEducatorSummarySend } = this.state
//     const { cohort, userStore } = this.props
//     const { role, profileId } = userStore

//     const cohortPhotos = cohort.photos
//     const publishablePhotos = cohortPhotos.filter(photo => photo.readyToPublish)

//     const awardedPhotos = cohortPhotos.filter(
//       photo => photo.awards.length > 0 && photo.isShortListed
//     )
//     const isCohortReviewed =
//       cohort.curators.length > 0 && cohort.curators.length === cohort.curatorReviews.length
//     const isCohortEnded = moment(cohort.endDate, 'YYYY-MM-DD').isBefore(
//       moment().format('YYYY-MM-DD')
//     )
//     const isCohortSendForReview = cohort.isReviewSend

//     const currentUserReview = cohort.curatorReviews.filter(
//       review => review.reviewerId === profileId
//     )
//     const completedAwardedPhotos = awardedPhotos.filter(
//       photo => photo.hasPeople === false || (photo.hasPeople === true && photo.totalMrRequired > 0)
//     ).length

//     const shouldReviewButtonDisable =
//       awardedPhotos.length > 0 && completedAwardedPhotos !== awardedPhotos.length

//     return (
//       <Box sx={{ px: { xs: 3, sm: 4, md: 6 }, pt: { xs: 5, md: 6, lg: 10 }, pb: { xs: 6 } }}>
//         <Box sx={{ pb: { xs: 7, sm: 10 } }}>
//           <Typography className='bold' variant='h1'>
//             {!isCohortEnded
//               ? 'Class Has Not Submitted'
//               : isCohortEnded && !isCohortSendForReview
//               ? 'Class Is in Submission Review'
//               : isCohortEnded && isCohortSendForReview && !isCohortReviewed
//               ? 'Class Is in Curatorial Review'
//               : isCohortReviewed && !isEducatorSummarySend
//               ? role === 'appadmin'
//                 ? 'Class Is in Award Summary Review'
//                 : 'Class Review is Complete'
//               : isCohortReviewed && isEducatorSummarySend
//               ? 'Class Is in Publishing Review'
//               : ''}
//           </Typography>
//           <Box sx={{ mt: { xs: 2 } }}>
//             {!isCohortEnded ? (
//               <Typography variant='paragraph' component='p'>
//                 This class is still active, and students have not completed their assignment. After
//                 the assignment submission due date, you can check submissions and send the review to
//                 curators.
//               </Typography>
//             ) : isCohortEnded && !isCohortSendForReview && role === 'appadmin' ? (
//               <Grid container>
//                 <Grid item md={8} sm={12} xs={12}>
//                   <Typography variant='paragraph' component='p'>
//                     The assignment submission date for this class has passed.
//                   </Typography>
//                   <Box sx={{ mt: {xs: 7, sm: 6} }}>
//                     <Typography variant='h2' className='strong'>
//                       Check Project Submissions and Send Review to Curators
//                     </Typography>
//                     <Typography variant='paragraph' component='ol' sx={{pl: 2, mt: 2}}>
//                       <li>
//                         Check that enough students have submitted projects for this class to be
//                         ready for curatorial review. If submission rate was low, consider talking to
//                         the class teacher about extending the submission date.
//                       </li>
//                       <li>Assign a review due date and send the review to curators.</li>
//                       <li>
//                         Once the review is sent:
//                         <ul>
//                           <li>
//                             curators for this class will receive an email letting them know that the
//                             class is available to review.
//                           </li>
//                           <li>
//                             curators for this class will see the review in the app next time they
//                             sign in.
//                           </li>
//                         </ul>
//                       </li>
//                     </Typography>
//                   </Box>
//                 </Grid>
//               </Grid>
//             ) : isCohortEnded && isCohortSendForReview && !isCohortReviewed ? (
//               <Grid container>
//                 <Grid item md={7} xs={12}>
//                   {role === 'appadmin' ? (
//                     <Box>
//                       <Typography variant='paragraph' component='p'>
//                         After all the curators submit their review for this class, you will be asked
//                         to process awards and notify students.
//                       </Typography>
//                       {this.getCuratorReviewStatus()}
//                     </Box>
//                   ) : (
//                     <Box>
//                       {this.getReviewStatusForCurators(cohort)}
//                       <Box sx={{ mt: '32px' }}>
//                         {currentUserReview.length > 0 ? (
//                           <Typography variant='paragraph' component='p'>
//                             Thank you for submitting your curatorial review. You can no longer edit
//                             awards for this class. After <i>all</i> curators for the class submit
//                             their review, the review will be considered complete and the{' '}
//                             <i>wrkxfmly</i> administrator will process the awarded photographs.
//                           </Typography>
//                         ) : (
//                           <Typography variant='paragraph' component='p'>
//                             When you are happy with your choice of awards for this class, please
//                             submit your review. Other curators for this class will be notified by
//                             email.
//                           </Typography>
//                         )}
//                       </Box>
//                     </Box>
//                   )}
//                 </Grid>
//               </Grid>
//             ) : isCohortReviewed && !isEducatorSummarySend && role === 'appadmin' ? (
//               <Box className='award-processing__review-summary-section--not-sent'>
//                 <Typography variant='paragraph' component='p'>
//                   All of the curators for this class have submitted their curatorial review.
//                 </Typography>
//                 {this.getCuratorReviewStatus()}
//                 <Typography variant='h2' className='strong' sx={{ mt: { xs: 7, sm: 6 } }}>
//                   Process Awarded Photographs and Send Summary
//                 </Typography>
//                 <Box component={'div'}>
//                   <Typography variant='paragraph' component='ol' sx={{ pl: { xs: 2 }, mt: 2 }}>
//                     <li>
//                       Via the ‘Class Photographs’ tab, begin the Model Release review process
//                       (adding models and notes, where applicable).
//                     </li>
//                     <li>Once complete, return here to send the review summary.</li>
//                     <li>
//                       Once the review summary is sent:
//                       <ul>
//                         <li>
//                           students will automatically receive an email with the results of their
//                           review.
//                         </li>
//                         <li>
//                           the teacher and teaching artist will automatically receive an email with a
//                           summary of all class awards.
//                         </li>
//                         <li>all users who next sign in to the web app will see award details.</li>
//                       </ul>
//                     </li>
//                   </Typography>
//                 </Box>
//                 <Mutation
//                   mutation={SEND_SUMMARY_MUTATION}
//                   onCompleted={() => {
//                     this.props.showToast('success', 'Review summary sent.')
//                   }}
//                 >
//                   {(onMutate, { data }) => (
//                     <>
//                       <Box className='award-processing__review-summary-section--button-section'>
//                         <Grid container sx={{ mt: { xs: 3 } }}>
//                           <Grid item xs={12} sm={6}>
//                             <Button
//                               onClick={e => this.handleSubmitSummary(e, onMutate, 'online')}
//                               variant='outlined'
//                               title={
//                                 <span className='icon-text-span'>
//                                   <Send size={18} /> Send Review Summary
//                                 </span>
//                               }
//                               disabled={isEducatorSummarySend || shouldReviewButtonDisable}
//                               sx={{ width: { xs: '100%', md: 'auto' } }}
//                             />
//                           </Grid>
//                           <Grid item xs={12} sm={10} lg={7}>
//                             <Typography variant='note' component='h3'>
//                               Review summary cannot be sent until all awarded photographs have Model
//                               Release notes.
//                             </Typography>
//                           </Grid>
//                         </Grid>

//                         <SendSummaryModal
//                           show={this.state.modalSummaryShow}
//                           onHide={modalClose}
//                           photoIds={[...publishablePhotos].map(photo => parseInt(photo.id))}
//                           cohortId={this.props.cohort.id}
//                           onMutate={onMutate}
//                           summaryType={this.state.summaryType}
//                         />
//                       </Box>
//                       <Grid container sx={{ mt: { xs: 3 } }} gap={{ xs: 3 }}>
//                         <Grid item xs={12} sm={10} lg={7}>
//                           <Typography variant='paragraph' component='p'>
//                             If the summary for this class was already sent outside of the web app,
//                             please indicate this below.
//                           </Typography>
//                         </Grid>
//                         <Grid item xs={12} sm={6}>
//                           <Button
//                             onClick={e => this.handleSubmitSummary(e, onMutate, 'offline')}
//                             variant='outlined'
//                             title='Mark Summary as Sent'
//                             disabled={isEducatorSummarySend || shouldReviewButtonDisable}
//                             sx={{ width: { xs: '100%', md: 'auto' } }}
//                           />
//                         </Grid>
//                       </Grid>
//                     </>
//                   )}
//                 </Mutation>
//               </Box>
//             ) : isCohortReviewed && role === 'curator' ? (
//               <Box>
//                 {this.getReviewStatusForCurators(cohort)}
//                 <Grid container sx={{ mt: { xs: 4 } }}>
//                   <Grid item xs={12} sm={10} lg={7}>
//                     <Typography variant='paragraph' component='p'>
//                       Thank you for submitting your curatorial review. You can no longer edit awards
//                       for this class. The <i>wrkxfmly</i> administrator will now process the awarded
//                       photographs and notify students, teachers, and teaching artists.
//                     </Typography>
//                   </Grid>
//                 </Grid>
//               </Box>
//             ) : isCohortReviewed && isEducatorSummarySend && role === 'appadmin' ? (
//               <>
//                 <Box className='award-processing__submission-review--description'>
//                   <Typography variant='paragraph' component='p'>
//                     Students have been notified of awards and any missing forms. As missing forms
//                     become available:
//                   </Typography>
//                   <ul>
//                     <li>
//                       review any Use Agreements and/or Model Releases per individual photographs.
//                     </li>
//                     <li>publish individual photographs as applicable.</li>
//                   </ul>
//                 </Box>
//                 <Box>
//                   {['appadmin'].includes(role) ? (
//                     isCohortReviewed && !isEducatorSummarySend ? null : isEducatorSummarySend ? (
//                       <Box sx={{mt: {xs: 7, sm: 6}}}>
//                         <Typography variant='h2' className='strong'>
//                           Review Summary Date
//                         </Typography>
//                         <Typography variant='paragraph' component='p'>
//                           {cohort.educatorSummaryAt
//                             ? moment(cohort.educatorSummaryAt, 'YYYY-MM-DD').format('MMMM D, YYYY')
//                             : moment().format('MMMM D, YYYY')}
//                         </Typography>
//                       </Box>
//                     ) : null
//                   ) : null}
//                 </Box>
//                 {this.getCuratorReviewStatus(cohort)}
//               </>
//             ) : null}

//             {isCohortEnded && !isCohortReviewed && role === 'appadmin' ? (
//               <>
//                 {isCohortSendForReview ? (
//                   <Box sx={{ mt: { xs: 7, sm: 6 } }}>
//                     <Typography variant='h2' className='strong'>
//                       Review Due Date
//                     </Typography>
//                     <Grid container sx={{ mt: { xs: 2 } }}>
//                       <Grid item xs={12} sm={10} lg={7}>
//                         <Typography variant='paragraph' component='p'>
//                           If you need to change the due date for this review, you can do so below.
//                           Curators will receive an email letting them know that the review due date
//                           has changed.
//                         </Typography>
//                       </Grid>
//                     </Grid>
//                   </Box>
//                 ) : null}
//                 <Mutation
//                   mutation={SEND_CURATORS_REVIEW_MUTATION}
//                   onCompleted={() => {
//                     this.setState({
//                       curatorReviewSend: true
//                     })
//                     let message = isCohortSendForReview
//                       ? 'New Due Date has been send to Curators'
//                       : 'Review has been send to Curators.'
//                     this.props.showToast('success', message)
//                   }}
//                 >
//                   {(onMutate, { data }) => (
//                     <Grid container sx={{ mt: { xs: 3, sm: 5 } }} gap={{ xs: 3 }}>
//                       <Grid item xs={12} sm={6.2} md={4} lg={3}>
//                         <DatePicker
//                           onChange={date =>
//                             this.handleFormChange({
//                               target: {
//                                 value: date,
//                                 name: 'curatorDueDate'
//                               }
//                             })
//                           }
//                           value={this.state.curatorDueDate}
//                           helperText={this.state.errors.curatorDueDate}
//                           error={this.state.errors.curatorDueDate}
//                           label='Review Due Date'
//                           className='custom-input-bg'
//                         />
//                       </Grid>
//                       <Grid item xs={12} sm={6.2} md={12}>
//                         <Button
//                           onClick={e => this.sendReviewToCurators(e, onMutate)}
//                           variant={isCohortSendForReview ? 'outlined' : 'contained'}
//                           sx={{ width: { xs: '100%', md: 'auto' } }}
//                           title={
//                             this.state.curatorReviewSendLoading ? (
//                               <Typography variant='span' className='icon-text-span'>
//                                 <Spinner
//                                   as='span'
//                                   animation='border'
//                                   size='sm'
//                                   role='status'
//                                   aria-hidden='true'
//                                 />{' '}
//                                 Sending...
//                               </Typography>
//                             ) : (
//                               <Box className='icon-text-span'>
//                                 <Send size={16} />
//                                 {isCohortSendForReview
//                                   ? 'Send New Due Date to Curators'
//                                   : 'Send Review to Curators'}
//                               </Box>
//                             )
//                           }
//                           disabled={
//                             this.state.curatorDueDate === null ||
//                             this.state.errors.curatorDueDate !== null ||
//                             this.state.curatorReviewSendLoading
//                           }
//                         />
//                       </Grid>
//                     </Grid>
//                   )}
//                 </Mutation>
//               </>
//             ) : null}

//             {isCohortEnded &&
//             isCohortSendForReview &&
//             !isEducatorSummarySend &&
//             role === 'appadmin' ? (
//               <>
//                 <Typography variant='h2' className='strong' sx={{ mt: { xs: 7, sm: 6 } }}>
//                   Reopen Curatorial Review(s)
//                 </Typography>
//                 <Grid container sx={{ mt: { xs: 2 } }} gap={{ xs: 3 }}>
//                   <Grid item xs={12} sm={10} lg={7}>
//                     <Typography variant='paragraph' component='p'>
//                       After curators submit, they can no longer change their award designations. You
//                       can reopen this review for one or more curators below.
//                     </Typography>
//                   </Grid>

//                   <Grid item xs={12} sm={6}>
//                     <Button
//                       onClick={e => {
//                         e.preventDefault()
//                         this.setState({
//                           showReopenCuratorReviewModal: true
//                         })
//                       }}
//                       sx={{ width: { xs: '100%', md: 'auto' } }}
//                       color='primary'
//                       title='Reopen Curatorial Review(s)'
//                       disabled={cohort.curatorReviews.length < 1}
//                     />
//                   </Grid>
//                 </Grid>
//                 <ReopenCuratorialReviewModal
//                   show={this.state.showReopenCuratorReviewModal}
//                   showToast={this.props.showToast}
//                   onHide={() =>
//                     this.setState({
//                       showReopenCuratorReviewModal: false
//                     })
//                   }
//                   curators={cohort.curators}
//                   curatorReviews={cohort.curatorReviews}
//                 />
//               </>
//             ) : null}

//             {currentUserReview.length > 0 && role === 'curator' ? null : (
//               <Box>
//                 {role === 'appadmin' && !isCohortReviewed && isCohortSendForReview ? (
//                   <>
//                     <Box sx={{ mt: { xs: 7, sm: 6 } }}>
//                       <Typography variant='h2' className='strong'>
//                         Submit Review(s)
//                       </Typography>
//                       <Grid container sx={{ mt: { xs: 2 } }} gap={{ xs: 3 }}>
//                         <Grid item xs={12} sm={10} lg={7}>
//                           <Typography variant='paragraph' component='p'>
//                             If one or more curators is unable to submit their own review, you can
//                             submit on their behalf using the button below.
//                           </Typography>
//                         </Grid>
//                         <Grid item xs={12} sm={6}>
//                           <Button
//                             onClick={e => {
//                               e.preventDefault()
//                               this.setState({
//                                 modalCuratorialReviewShow: true
//                               })
//                             }}
//                             sx={{ width: { xs: '100%', md: 'auto' } }}
//                             color='primary'
//                             title={'Submit Curatorial Review(s)'}
//                           />
//                         </Grid>
//                       </Grid>
//                       <SubmitCuratorialReviewAdminModal
//                         showToast={this.props.showToast}
//                         cohortId={this.props.cohort.id}
//                         curators={cohort.curators}
//                         curatorReviews={cohort.curatorReviews}
//                         show={this.state.modalCuratorialReviewShow}
//                         onHide={() => {
//                           this.setState({
//                             modalCuratorialReviewShow: false
//                           })
//                         }}
//                       />
//                     </Box>
//                   </>
//                 ) : role === 'curator' ? (
//                   <>
//                     <Grid container sx={{ mt: { xs: 3 } }}>
//                       <Grid item xs={12} sm={6}>
//                         <Button
//                           onClick={e => {
//                             e.preventDefault()
//                             this.setState({
//                               modalCuratorialReviewShow: true
//                             })
//                           }}
//                           variant='contained'
//                           title={'Submit Your Curatorial Review'}
//                           sx={{ width: { xs: '100%', md: 'auto' } }}
//                         />
//                       </Grid>
//                       <Grid item xs={12} sm={10} lg={7}>
//                         <Typography variant='note' component='p'>
//                           You will not be able to edit your awards after you submit this review.
//                         </Typography>
//                       </Grid>
//                     </Grid>
//                     <SubmitCuratorialReviewModal
//                       showToast={this.props.showToast}
//                       cohortId={this.props.cohort.id}
//                       show={this.state.modalCuratorialReviewShow}
//                       onHide={() => {
//                         this.setState({
//                           modalCuratorialReviewShow: false
//                         })
//                       }}
//                     />
//                   </>
//                 ) : null}
//               </Box>
//             )}
//           </Box>
//         </Box>
//         <Box className='award-processing__more-information-section'>
//           <Typography variant='h2' className='strong' sx={{ mt: { xs: 6 } }}>
//             More about This Review
//           </Typography>
//           <Grid container sx={{ mt: { xs: 4 } }} rowGap={{ xs: 4 }}>
//             <Grid item xs={12} sm={6} md={4}>
//               <Typography variant='dataLabel' component='div'>
//                 Review Status
//               </Typography>
//               <Typography variant='dataLabel' className='content' component='div'>
//                 {!isCohortEnded
//                   ? 'Class Has Not Submitted'
//                   : isCohortEnded && !isCohortSendForReview
//                   ? 'In Submission Review'
//                   : isCohortEnded && isCohortSendForReview && !isCohortReviewed
//                   ? 'In Curatorial Review'
//                   : isCohortReviewed && !isEducatorSummarySend
//                   ? role === 'appadmin'
//                     ? 'In Award Summary Review'
//                     : 'Review Complete'
//                   : isCohortReviewed && isEducatorSummarySend
//                   ? 'In Publishing Review'
//                   : ''}
//               </Typography>
//             </Grid>
//             <Grid item xs={12} sm={6} md={4}>
//               <Typography variant='dataLabel' component='div'>
//                 Assignment Submission Due Date
//               </Typography>
//               <Typography variant='dataLabel' className='content' component='div'>
//                 {moment(cohort.endDate, 'YYYY-MM-DD').format('MMMM D, YYYY')}
//               </Typography>
//             </Grid>
//             <Grid item xs={12} sm={6} md={4}>
//               <Typography variant='dataLabel' component='div'>
//                 Curators
//               </Typography>
//               {this.props.cohort.curators.map((curator, index) => (
//                 <Box key={index} className='class-details__curator-names'>
//                   <Link
//                     to='#'
//                     onClick={() =>
//                       this.setState({
//                         modalCuratorDetailsShow: true,
//                         modalUserIndex: index
//                       })
//                     }
//                   >
//                     <Typography variant='link' className='labeled-data large'>
//                       {curator.name}
//                     </Typography>
//                   </Link>
//                   <UserDetailsModal
//                     show={this.state.modalCuratorDetailsShow && this.state.modalUserIndex === index}
//                     onHide={modalClose}
//                     name={curator.name}
//                     bio={curator.bio}
//                     userType='Curator'
//                     website={curator.website}
//                     email={curator.email}
//                     mobileNumber={curator.mobileNumber}
//                     instagramHandle={curator.instagramHandle}
//                     userId={curator.userId}
//                   />
//                 </Box>
//               ))}
//             </Grid>
//             <Grid item xs={12} sm={6} md={4}>
//               <Typography variant='dataLabel' component='div'>
//                 Curatorial Review Start Date
//               </Typography>
//               <Typography variant='dataLabel' className='content' component='div'>
//                 {cohort.sendForReviewOn
//                   ? moment(cohort.sendForReviewOn, 'YYYY-MM-DD').format('MMMM D, YYYY')
//                   : 'Review Has Not Started'}
//               </Typography>
//             </Grid>
//             <Grid item xs={12} sm={6} md={4}>
//               <Typography variant='dataLabel' component='div'>
//                 Curatorial Review Due Date
//               </Typography>
//               <Typography variant='dataLabel' className='content' component='div'>
//                 {cohort.curatorsDueDate
//                   ? moment(cohort.curatorsDueDate, 'YYYY-MM-DD').format('MMMM D, YYYY')
//                   : 'Due Date Not Assigned'}
//               </Typography>
//             </Grid>
//             <Grid item xs={12} sm={6} md={4}>
//               <Typography variant='dataLabel' component='div'>
//                 Curatorial Review Completed Date
//               </Typography>
//               <Typography variant='dataLabel' className='content' component='div'>
//                 {cohort.curatorReviews.length > 0 &&
//                 cohort.curatorReviews.length === cohort.curators.length
//                   ? this.getCuratorCompletedDate(cohort).format('MMMM D, YYYY')
//                   : 'Curatorial Review Not Completed'}
//               </Typography>
//             </Grid>
//           </Grid>
//         </Box>
//       </Box>
//     )
//   }
// }

export default inject('userStore')(AwardProcessing)
