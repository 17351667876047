import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import GATag from '../../../../utils/GATag'
import Button from '../../../elements/Button'
import FormCheckbox from '../../../elements/FormCheckbox'
import ModelReleasesNoteEditor from '../../../molecules/ModelReleasesNoteEditor'
import { GET_MODEL_RELEASES_DATA, MANAGE_MR_NUMBERS, TOGGLE_HAS_PEOPLE_MUTATION } from '../../../../queries/PhotoDetailsQueries'
import ModalReleaseListSection from './ModalReleaseListSection'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { getFormattedStatus } from '../../../../utils/commonFunctions'
import { getTextContentByPage } from '../../../../content/textContent'
import { User } from 'react-feather'

const ModalReleaseSection = props => {
    const { photoId, student, photoDetails, role } = props
    const birthDay =
      student && !!moment().diff(student.dob, 'years') === false
        ? 0
        : student && moment().diff(student.dob, 'years')
    const [hasPeople, setHasPeople] = useState(false)
    const [totalMrRequired, setTotalMrRequired] = useState(0)
    const [showMrModal, setShowMrModal] = useState(false)
    const [modelReleases, setModelReleases] = useState([])
    const [activeModelReleases, setActiveModelReleases] = useState([])
    const [inactiveModelReleases, setInactiveModelReleases] = useState([])
    const [activeMr, setActiveMr] = useState('')
    const [isStudentMinor] = useState(birthDay < 18)
    const [mrApproved, setMrApproved] = useState(false)
    // const [isActiveUaRejected, setIsActiveUaRejected] = useState(false)

    const [
      hasPeopleMutation,
      { loading: hasPeopleLoading, data: hasPeopleData, error: hasPeopleError }
    ] = useMutation(TOGGLE_HAS_PEOPLE_MUTATION, {
      onCompleted(data) {
        // setHasPeople(!hasPeople)
        props.refetchPhoto && props.refetchPhoto()
      }
    })

    const [
      updateMrRequiredCountMutation,
      {
        loading: updateMrRequiredCountLoading,
        data: updateMrRequiredCountData,
        error: updateMrRequiredCountError
      }
    ] = useMutation(MANAGE_MR_NUMBERS, {
      onCompleted(data) {
        GATag.setEvent({
          category: 'model-release',
          action: 'open-single',
          label: 'open single',
          value: 1
        })
        props.refetchPhoto && props.refetchPhoto()
      }
    })


    const {
      loading: dataFetchingLoader,
      data: mrData,
      error: mrError,
      refetch: refetchMrData
    } = useQuery(GET_MODEL_RELEASES_DATA, {
      variables: {
        id: photoId
      },
      fetchPolicy: 'network-only',
      onCompleted(data) {
        setModelReleases(data.allModelReleases)
        setInactiveModelReleases(data.inactiveModelReleases)
        setActiveModelReleases(data.activeModelReleases)
        setActiveMr(data.activeModelReleases)
        setMrApproved(data.approvedMr)
      }
    })

    useEffect(() => {
        setHasPeople(photoDetails.hasPeople)
    }, [photoDetails.hasPeople])

    useEffect(() => {
      setTotalMrRequired(photoDetails.totalMrRequired)
    }, [photoDetails.totalMrRequired])

    return (
      <Fragment>
        <Box
          component={'div'}
          className='body__photo-release'
          sx={{
            pt: {xs: 4},
            px: {xs: 0},
            pb: {xs: 8, lg:10},
            mb: 0
          }}
        >
          <Accordion
            defaultExpanded={["student", "teacher", "facilitator"].includes(role) ? true : false}
            sx={{
              boxShadow: 'none',
              '.MuiAccordionSummary-content': {
                m: 0
              },
              '.MuiAccordionSummary-root': {
                minHeight: 'fit-content'
              }
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="mr-content"
              id="mr-header"
              sx={{alignItems: 'baseline', p: 0}}
            >
              <Box component={'div'} className='award-nominator__publish-photograph--header'>
                <Typography variant='h2' className='strong' sx={{mb: {xs: 1}}}>
                  Model(s) in This Photograph ({hasPeople ? totalMrRequired : 0})
                </Typography>
                {['student'].includes(role) && hasPeople && totalMrRequired !== activeMr.length ? (
                  getFormattedStatus('AlertTriangle', `Add Model Release(s) to this photograph.`)
                ) : ['appadmin', 'teacher', 'facilitator'].includes(role) &&
                  hasPeople &&
                  totalMrRequired !== mrApproved.length ? (
                  getFormattedStatus('AlertTriangle', `This photograph needs approved Model Release(s).`)
                ) : ['appadmin'].includes(role) &&
                  (hasPeople === null || (hasPeople && totalMrRequired === 0)) ? (
                  getFormattedStatus('AlertTriangle', `Identify model(s) in this photograph.`)
                ) : null}
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{p: 0}}>
              <Box component={'div'} className='photo-release__instruction-section'>
                {role === 'appadmin' ? (
                  <Typography variant='paragraph' component='p'
                    sx={{
                      mt:{xs: 4, sm: '29px', md: 4}
                    }}
                  >
                    {/* If this photograph contains recognizable people, add one model for each person.
                    The photograph cannot be published until each model has a granted Model Release
                    that has been approved by Working Assumptions. */}
                    {getTextContentByPage('PHOTOGRAPH_DETAIL', role.toUpperCase(), `MODELS_INTRO`)}
                  </Typography>
                ) : (
                  <>
                    {hasPeople ? (
                      <>
                        {photoDetails && photoDetails.mrAdminNotes ? (
                          <Typography variant="paragraph"
                            sx={{
                              mt:{xs: 4},
                              mb:{xs: 2},
                            }}
                            component="p"
                            >{photoDetails.mrAdminNotes}</Typography>
                        ) : null}
                        <Typography variant="paragraph"
                            sx={{
                              mb:"1rem",
                            }}
                            component="p"
                            >
                          {['teacher', 'facilitator'].includes(role) ? 'Please have the student provide a signed Model Release for each recognizable person in this photograph. Working Assumption will review each submission to ensure that the form is valid.': 'Please provide a signed Model Release for each recognizable person in this photograph. Working Assumptions will review each submission to ensure that the form is valid.'}
                        </Typography>
                      </>
                    ) : hasPeople === false ? (
                      <>
                        <Typography variant="paragraph"
                            sx={{
                              mt:{xs: 4}
                            }}
                            component="p"
                            >
                          {getTextContentByPage('PHOTOGRAPH_DETAIL', role.toUpperCase(), `MODELS_INTRO`)}
                        </Typography>
                      </>
                    ) : null}
                  </>
                )}
              </Box>
              {role === 'appadmin' ? (
                <>
                  <Box component={'div'} className='photo-release__hasNoPeople--checkbox' sx={{mt: {xs: 4}, mb:{xs: 2} }}>
                    <FormCheckbox
                      onChange={e => {
                        setHasPeople(e.target.checked)
                        hasPeopleMutation({
                          variables: {
                            photoId: photoId,
                            hasPeople: e.target.checked
                          }
                        })
                      }}
                      name='hasPeople'
                      checked={hasPeople === false}
                      label='This photograph does not require Model Releases.'
                      color='primary'
                      disabled={totalMrRequired > 0}
                    />
                  </Box>

                  <Button
                    onClick={e => {
                      setTotalMrRequired(totalMrRequired + 1)
                      updateMrRequiredCountMutation({
                        variables: {
                          photoId: photoId,
                          opType: 'add'
                        }
                      })
                    }}
                    title={
                      <span className='icon-text-span'>
                        <User size={18} /> Add a Model
                      </span>
                    }
                    disabled={hasPeople === false}
                    sx={{width: {xs: '100%', sm: '33%', md: 'auto'}}}
                  />
                </>
              ) : null}
              {hasPeople ? (
                <ModalReleaseListSection
                  role={role}
                  photoId={photoId}
                  photoDetails={photoDetails}
                  modelReleases={modelReleases}
                  activeModelReleases={activeModelReleases}
                  totalMrRequired={totalMrRequired}
                  notAddedMrCount={parseInt(totalMrRequired) - parseInt(activeModelReleases.length)}
                  refetchPhoto={props.refetchPhoto}
                  studentDetails={props.student}
                  toggleEditing={props.toggleEditing}
                  refetchMrData={refetchMrData}
                  setIsModalOpen={props?.setIsModalOpen}
                />
              ) : null}
              {role === 'appadmin' && totalMrRequired > 0 ? (
                <Box component={'div'} sx={{mt: {xs: 5}}}>
                  <Typography variant='paragraph'
                    sx={{
                      mb:'1rem',
                    }}
                    component='p'
                  >
                    Add a note explaining details about the required Model Release(s). The student,
                    teacher, and teaching artist will see the note when they view this photograph.
                  </Typography>
                  <ModelReleasesNoteEditor
                    photoId={photoId}
                    photo={photoDetails}
                    toggleEditing={props.toggleEditing}
                  />
                </Box>
              ) : null}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Fragment>
    )
}

export default ModalReleaseSection