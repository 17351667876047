import React, { Component, Fragment } from 'react'
import './photorelease.scss'
import { observer, inject } from 'mobx-react'
import Button from '../elements/Button.js'
import { Mutation } from '@apollo/client/react/components'
import { AlertTriangle, User } from 'react-feather'
import { gql } from '@apollo/client'
import ModelReleasesList from './ModelReleasesList'
import FormCheckbox from '../elements/FormCheckbox'
import ModelReleasesNoteEditor from './ModelReleasesNoteEditor'
import { getAllCuurentMr } from '../../utils/commonFunctions'
import GATag from '../../utils/GATag'
import { Typography } from '@mui/material'

const toggleHasPeopleMutation = gql`
  mutation UpdatePhotoHasPeople($photoId: ID!, $hasPeople: Boolean!) {
    updatePhotoHasPeople(photoId: $photoId, hasPeople: $hasPeople) {
      id
    }
  }
`

const MANAGE_MR_NUMBERS = gql`
  mutation ManageMrNumberPhotos($photoId: ID!, $opType: String!) {
    manageMrNumberPhotos(photoId: $photoId, opType: $opType) {
      id
    }
  }
`
class ModelReleaseSummary extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      openOne: false,
      photo: props.photo ? props.photo : {},
      hasPeople: this.props.hasPeople,
      modelReleases: this.props.modelReleases,
      resendMessage: false,
      resendUuid: '',
      totalMrRequired: this.props.totalMrRequired < 0 ? 0 : this.props.totalMrRequired || 0
    }
  }

  componentWillReceiveProps(nextProps) {
    // if( nextProps.photo.id !== this.state.photo.id )
    //  {
      this.setState({
        hasPeople: nextProps.hasPeople,
        modelReleases: nextProps.modelReleases,
        totalMrRequired: nextProps.totalMrRequired < 0 ? 0 : nextProps.totalMrRequired
      })
    // }
  }

  handleChange = (name, onMutate) => event => {
    this.setState({
      [name]: event.target.checked
    })
    onMutate({
      variables: {
        photoId: this.props.photoId,
        hasPeople: event.target.checked
      },
      refetchQueries: [`ProjectDetails`]
    })
    // this.setState({
    //   totalMrRequired: this.state.totalMrRequired || 0
    // })
  }

  manageModelRelease(onMutate, uuid, type) {
    if (type === 'resend') {
      this.setState({ resendMessage: true, resendUuid: uuid })
    }
    onMutate({
      variables: {
        photoId: this.props.photoId,
        modelUuid: uuid,
        manageType: type
      },
      refetchQueries: [`PhotoDetails`]
    })
    if (type === 'resend') {
      setTimeout(() => this.setState({ resendMessage: false, resendUuid: '' }), 5000)
    }
  }

  handlePDFSubmitSuccess = () => {
    this.setState({
      ...this.state,
      modelReleases: [...this.state.modelReleases, { createdAt: Date.now(), linkToFile: 'empty' }]
    })
    alert('Model release successfully uploaded')
  }

  addNewModelToPhoto = (e, onMutate, type) => {
    GATag.setEvent({
      category: 'model-release',
      action: 'open-single',
      label: 'open single',
      value: 1
    })
    e.preventDefault()
    // let mrNumber = this.state.totalMrRequired
    // let hasPeople = this.state.hasPeople
    // if (type === 'delete') {
    //   mrNumber = mrNumber - 1
    // } else {
    //   mrNumber = mrNumber + 1
    //   hasPeople = [null, undefined, ''].includes(hasPeople) ? true : hasPeople
    // }
    onMutate({
      variables: {
        photoId: this.props.photoId,
        opType: type
      },
      // refetchQueries: [`PhotoDetails`]
    })
    // this.setState({
    //   totalMrRequired: mrNumber < 0 ? 0 : mrNumber,
    //   hasPeople: hasPeople
    // })
    e.target.disabled = false
  }

  checkAdminApprovedMr = modelReleases => {
    return modelReleases.filter(mr => mr.adminDecision === 'Approve' && mr.mrStatus === 'Completed')
  }

  render() {
    const { photo } = this.props
    const { role } = this.props.userStore
    const activeMr = getAllCuurentMr(this.state.modelReleases)
    const shouldShow = (photo &&
      photo.submitted &&
      photo.project.cohort.reviewed &&
      photo.awards.length > 0 &&
      role === 'appadmin') ||
    (photo &&
      photo.submitted &&
      photo.project.cohort.reviewed &&
      photo.summarySent &&
      photo.hasPeople !== null &&
      ['student', 'teacher', 'facilitator'].includes(role))
    return shouldShow ? (
      <Fragment>
        <div className='body__photo-release'>
          <div className='award-nominator__publish-photograph--header'>

            <Typography variant="h2">
              Model(s) in This Photograph ({this.state.hasPeople ? this.state.totalMrRequired : 0})
            </Typography>
          </div>

          {['student'].includes(role) &&
          this.state.hasPeople &&
          this.state.totalMrRequired !== activeMr.length ? (
            <Typography variant="h5" className='semi-strong h5_alert'>
              <span className='icon-text-span'>
                <AlertTriangle size={16} /> Add Model Release(s) to this photograph.
              </span>
            </Typography>
          ) : ['appadmin', 'teacher', 'facilitator'].includes(role) &&
            this.state.hasPeople &&
            this.state.totalMrRequired !==
              this.checkAdminApprovedMr(this.state.modelReleases).length ? (
            <Typography variant="h5" className='semi-strong h5_alert'>
              <span className='icon-text-span'>
                <AlertTriangle size={16} /> This photograph needs approved Model Release(s).
              </span>
            </Typography>
          ) : ['appadmin'].includes(role) &&
            (this.state.hasPeople === null ||
              (this.state.hasPeople && this.state.totalMrRequired === 0)) ? (
            <Typography variant="h5" className='semi-strong h5_alert'>
              <span className='icon-text-span'>
                <AlertTriangle size={16} /> Identify model(s) in this photograph.
              </span>
            </Typography>
          ) : null}

          <div className='photo-release__instruction-section'>
            {role === 'appadmin' ? (
              <Typography
                  variant="paragraph"
                  sx={{
                    mb:"1rem",
                  }}
                  component="p"
                >
                If this photograph contains recognizable people, add one model for each person.
              </Typography>
            ) : (
              <>
                {this.state.hasPeople ? (
                  <>
                    {this.props.adminNotes ? <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"
                >{this.props.adminNotes}</Typography> : null}
                    <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"
                >
                      The photograph cannot be published until each model has a granted Model
                      Release that has been approved by Working Assumptions.
                    </Typography>
                  </>
                ) : this.state.hasPeople === false ? (
                  <>
                    <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"
                >
                      This photograph does not contain recognizable people, so it does not require a
                      Model Release.
                    </Typography>
                  </>
                ) : null}
              </>
            )}
          </div>
          {role === 'appadmin' ? (
            <>
              <Mutation
                mutation={toggleHasPeopleMutation}
                onCompleted={() => {
                  this.props.refetchPhoto && this.props.refetchPhoto()
                  this.props.fetchProject && this.props.fetchProject()
                }}
              >
                {(onMutate, { data }) => {
                  return (
                    <div className='photo-release__hasNoPeople--checkbox'>
                      <FormCheckbox
                        onChange={this.handleChange('hasPeople', onMutate)}
                        name='hasPeople'
                        checked={this.state.hasPeople === false}
                        label='This photograph does not contain any recognizable people.'
                        color='primary'
                        disabled={this.state.totalMrRequired > 0}
                      />
                    </div>
                  )
                }}
              </Mutation>
              <Mutation
                mutation={MANAGE_MR_NUMBERS}
                onCompleted={() => {
                  this.props.refetchPhoto && this.props.refetchPhoto()
                  this.props.fetchProject && this.props.fetchProject()
                }}
              >
                {(onMutate, { data }) => {
                  return (
                    <Button
                      onClick={e => this.addNewModelToPhoto(e, onMutate, 'add')}
                      variant='outlined'
                      title={
                        <span className='icon-text-span'>
                          <User size={18} /> Add a Model
                        </span>
                      }
                      disabled={this.state.hasPeople === false}
                    />
                  )
                }}
              </Mutation>
            </>
          ) : null}
          {this.state.hasPeople ? (
            <ModelReleasesList
              modelReleases={this.state.modelReleases}
              refetchPhoto={this.props.refetchPhoto}
              photoId={this.props.photoId}
              studentDetails={this.props.studentDetails}
              toggleEditing={this.props.toggleEditing}
              totalMrRequired={this.state.totalMrRequired}
              manageNewModel={this.addNewModelToPhoto}
              manageModelQuery={MANAGE_MR_NUMBERS}
              fetchProject={this.props.refetchProject}
            />
          ) : null}
          {role === 'appadmin' && this.state.totalMrRequired > 0 ? (
            <div className='mr-note__section'>
              <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"
                >
                Add a note explaining details about the required Model Release(s). The student,
                teacher, and teaching artist will see the note when they view this photograph.
              </Typography>
              <ModelReleasesNoteEditor
                photoId={this.props.photoId}
                photo={this.props.photo}
                toggleEditing={this.props.toggleEditing}
              />
            </div>
          ) : null}
        </div>
      </Fragment>
    ) : null
  }
}

export default inject('userStore')(observer(ModelReleaseSummary))
